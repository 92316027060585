import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay, EffectCoverflow } from 'swiper';

import OrangeArrow from 'src/assets/homepage/orangeArrow.svg';
import BannerBg from 'src/assets/homepage/bannerBg.png';
import i18n from 'src/i18n';

import bannerStyles from './styles.module.scss';

import SwiperCard from './components/SwiperCard';

import styles from './styles.module.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/effect-coverflow';
import './customizeSwiper.css';

@observer
class BannerSection extends React.Component {
  render() {
    const { viewModel, browser } = this.props;
    return (
      <section
        style={{ '--src': `url(${BannerBg})` }}
        className={clsx(bannerStyles.bannerSection, bannerStyles.bannerSection_layout)}
      >
        <div className={clsx(bannerStyles.bannerFlexBox, bannerStyles.bannerFlexBox_layout)}>
          <div className={clsx(bannerStyles.bannerFlexBox_item)}>
            <div className={clsx('banner', styles.sectionContainer)}>
              <Swiper
                onAfterInit={viewModel.onSwiper}
                modules={[Pagination, Navigation, Autoplay, EffectCoverflow]}
                navigation={{
                  prevEl: '.slidePre',
                  nextEl: '.slideNext'
                }}
                pagination={{
                  type: 'bullets',
                  clickable: true,
                  bulletActiveClass: 'active-bullet',
                  bulletClass: 'bullet'
                }}
                autoplay={{
                  disableOnInteraction: false
                }}
                loop
                loopedSlides={10}
                slidesPerView={1.1}
                centeredSlides
                spaceBetween="2%"
                className={styles.swiperContainer}
                effect="coverflow"
                coverflowEffect={{
                  rotate: 0,
                  depth: 0
                }}
                breakpoints={{
                  768: {
                    slidesPerView: 1.5,
                    modules: [Pagination, Navigation, Autoplay, EffectCoverflow],
                    coverflowEffect: {
                      rotate: 0,
                      depth: 300
                    },
                    cssMode: false
                  },
                  1060: {
                    slidesPerView: 'auto',
                    modules: [Pagination, Navigation, Autoplay, EffectCoverflow],
                    coverflowEffect: {
                      rotate: 0,
                      depth: 300
                    },
                    cssMode: false
                  },
                  1725: {
                    slidesPerView: 2.42,
                    modules: [Pagination, Navigation, Autoplay, EffectCoverflow],
                    coverflowEffect: {
                      rotate: 0,
                      depth: 300
                    },
                    cssMode: false
                  }
                }}
              >
                {
                  viewModel.slideItems?.map((el) => {
                    return (
                      <SwiperSlide key={el.id}>
                        <SwiperCard viewModel={el} />
                      </SwiperSlide>
                    );
                  })
                }
                <div className={clsx(styles.slidePre, 'slidePre', viewModel.isLoading && styles.isLoading)}>
                  <img
                    src={OrangeArrow}
                    alt="arrow"
                    className={styles.slide_pre}
                  />
                </div>
                <div className={clsx(styles.slideNext, 'slideNext', viewModel.isLoading && styles.isLoading)}>
                  <img
                    src={OrangeArrow}
                    alt="arrow"
                    className={styles.slide_next}
                  />
                </div>
              </Swiper>
            </div>
          </div>
          <div className={clsx(bannerStyles.bannerFlexBox_item)}>
            <h5 className={clsx(bannerStyles.description_box, bannerStyles.description_box_layout)}>
              <pre className={clsx(bannerStyles.description)}>
                {i18n.t('home_banner_slogan')}
              </pre>
            </h5>
          </div>
          <div className={clsx(bannerStyles.bannerFlexBox_spacer1)} />
        </div>
      </section>
    );
  }
}

BannerSection.propTypes = {
  viewModel: PropTypes.object,
  browser: PropTypes.string
};

BannerSection.defaultProps = {
  viewModel: {},
  browser: ''
};

export default BannerSection;
