import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import InputViewModel from './viewModel';

import ShowPassword from 'src/assets/account/showPassword.png';
import HidePassword from 'src/assets/account/hidePassword.png';

import styles from './style.module.scss';

@observer
class InputView extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new InputViewModel(props);
  }

  // handlerFocus = () => {
  //   this.vm.inputContainerRef.current.classList.remove(styles.notValid);
  //   this.vm.handlerMessageHide();
  // };

  // handlerBlur = () => {
  //   if (!this.props.valid) {
  //     this.vm.inputContainerRef.current.classList.add(styles.notValid);
  //     this.vm.handlerMessageShow();
  //   }
  // };

  render() {
    const { onBlur, inputContainerStyles, inputStyle, onSubmit } = this.props;
    return (
      <>
        <div className={styles.container}>
          <div
            className={clsx(
              styles.inputContainer,
              !this.vm.isPassword && styles.active,
              inputContainerStyles
            )}
            ref={this.vm.inputContainerRef}
          >
            <form className={styles.form} onSubmit={onSubmit}>
              <input
                type={this.vm.getType}
                className={clsx(styles.input, inputStyle)}
                value={this.props.value}
                onChange={this.props.handlerChange}
                onFocus={this.handlerFocus}
                // onBlur={this.handlerBlur}
                onBlur={onBlur}
                placeholder={this.vm.props.placeholder}
                autoComplete="off"
                size={15}
              />
            </form>
          </div>

          {this.vm.isPassword && (
            <div
              style={{ '--src': `url(${this.vm.isShowPassword ? ShowPassword : HidePassword})` }}
              className={clsx(styles.showPassword, styles.showPassword_layout)}
              onClick={this.vm.handlerSetShowPassword}
            />
          )}
        </div>
        <div className={styles.messageContainer}>
          {this.vm.isShowMessage && this.vm.props.message}
        </div>
      </>
    );
  }
}

InputView.propTypes = {
  value: PropTypes.string.isRequired,
  handlerChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  // 格式錯誤提示.
  message: PropTypes.string,
  // 提示.
  placeholder: PropTypes.string,
  // 驗證.
  valid: PropTypes.bool,
  // input 類型.
  type: PropTypes.string
};

InputView.defaultProps = {
  onSubmit: (event) => {
    event.stopPropagation();
    event.preventDefault();
  },
  // 錯誤提示.
  message: '',
  // 輸入提示.
  placeholder: '',
  // 不驗證, 故永遠是 true.
  valid: true,
  // input 類型.
  type: 'text'
};

export default InputView;
