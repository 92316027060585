import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';

class PagesRootViewModel {

}

export default PagesRootViewModel;
