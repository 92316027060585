import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';

import notiIcon from 'src/assets/header/notiIcon.svg';
import redRound from 'src/assets/header/redRound.svg';
import CloseBtn from 'src/assets/account/closeBtn.svg';

import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';

import AlertView from 'src/components/Alert';
import Quill from 'src/components/Quill';
import MenuView from '../Menu';
import NotificationsView from './component/Notifications';
import AnnouncementsView from './component/Announcements';

import MessagesViewModel from './viewModel';

import styles from './style.module.scss';


@observer
class MessagesView extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new MessagesViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate() {
    // profile 可能是 null or object.
    this.vm.didUpdate(this.props);
  }

  componentWillUnmount() {
    this.vm.unMount();
  }

  render() {
    return (
      <>
        <div
          style={{ '--src': `url(${notiIcon})` }}
          className={clsx(styles.notifications, styles.notifications_layout)}
          onClick={() => this.vm.onMessageIconClick(this.props.profile)}
        >
          {this.vm.profile && (
            <>
              {
                this.vm.totalUnreadCount > 0 && (
                  <div className={clsx(styles.notiCount, styles.notiCount_layout)}>
                    <h5
                      className={clsx(
                        styles.notiCountText,
                        styles.notiCountText_layout
                      )}
                    >
                      {this.vm.totalUnreadCount > 100 ? '99+' : this.vm.totalUnreadCount}
                    </h5>
                  </div>
                )
              }
            </>
          )}
        </div>
        {this.vm.menuVM.isOpen && (
          <MenuView vm={this.vm.menuVM}>
            <div className={styles.menuContainer}>
              <div className={styles.titleMain}>
                {i18n.t('message_messageCenter')}
              </div>
              <div
                className={styles.closeBtnContainer}
                onClick={this.vm.menuVM.handlerCloseMenu}
              >
                <img src={CloseBtn} alt="closeBtn" className={styles.closeBtn} />
              </div>

              <div className={clsx(styles.tab, !this.vm.profile && styles.singleTab)}>
                {this.vm.profile && (
                  <div
                    className={styles.item}
                    onClick={this.vm.setTypeNotification}
                  >
                    <div
                      className={clsx([
                        styles.title,
                        this.vm.type === 'notification' && styles.active
                      ])}
                    >
                      <div className={styles.mr15}>
                        {i18n.t('message_notification')}
                      </div>
                      {
                        (this.vm.type === 'notification' && this.vm.totalUnreadCount !== 0) && (
                          <div className={styles.redOuter}>
                            <div className={styles.unreadCount}>
                              {this.vm.totalUnreadCount > 100 ? '99+' : this.vm.totalUnreadCount}
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                )}

                <div
                  className={styles.item}
                  onClick={this.vm.setTypeAnnouncement}
                >
                  <div
                    className={clsx([
                      styles.title,
                      this.vm.type === 'announcement' && styles.active
                    ])}
                  >
                    {i18n.t('message_announcement')}
                  </div>
                </div>
              </div>

              <div className={styles.list} ref={this.vm.listRef}>
                <div
                  className={clsx([
                    styles.toggleContainer,
                    this.vm.type === 'notification' && styles.active
                  ])}
                >
                  <NotificationsView
                    list={this.vm.notifications}
                    onClickItem={this.vm.onClickNotification}
                    onEnterWaypoint={this.vm.handlerNotificationsListAPI}
                    isReady={this.vm.isReadyNotifications}
                    handlerAllReadAPI={this.vm.handlerAllReadAPI}
                  />
                </div>

                <div
                  className={clsx([
                    styles.toggleContainer,
                    this.vm.type === 'announcement' && styles.active
                  ])}
                >
                  <AnnouncementsView
                    list={this.vm.announcements}
                    onClickItem={this.vm.onClickAnnouncement}
                    onEnterWaypoint={this.vm.handlerAnnouncementsListAPI}
                    isReady={this.vm.isReadyAnnouncements}
                  />
                </div>
              </div>
            </div>
          </MenuView>
        )}
        <AlertView
          vm={this.vm}
          showButtons={false}
          showClose={false}
          className={styles.zIndex}
          contentContainerStyles={styles.containerStyles}
        >
          <div className={styles.modalContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.announceModalTitle}>
                {this.vm.openAnnounceTitle}
              </div>
              <div
                className={styles.announceModalClose}
                onClick={this.vm.handlerCloseAlert}
              >
                <img src={CloseBtn} alt="CloseBtn" className={styles.modalCloseBtn} />
              </div>
            </div>
            <div className={styles.quillContainer}>
              <Quill viewModel={this.vm.quillViewModel} />
            </div>
          </div>
        </AlertView>
      </>
    );
  }
}

export default withProfile(withRouter(MessagesView), false);
