import { makeObservable, observable, action, computed } from 'mobx';

import SwiperCardViewModel from './components/SwiperCard/viewModel';

export default class BannerSectionViewModel {
  @observable slideItems = [];
  @observable swiper;

  @observable isLoading = true;

  constructor() {
    makeObservable(this);
  }

  @action
  deserialize = (data) => {
    this.slideItems = data.map((el) => new SwiperCardViewModel(el));
  }

  @action
  updateData = (data) => {
    this.deserialize(data);
    this.swiper.updateSlides();
    if (this.slideItems.length <= 10) {
      this.swiper.slideTo(this.slideItems.length);
    } else {
      this.swiper.slideTo(this.slideItems.length - (this.slideItems.length - 10));
    }
    this.isLoading = false;
  }

  @action
  onSwiper = (swiper) => {
    this.swiper = swiper;
  }
}
