import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import styles from './styles.module.css';

@observer
class SwiperCard extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <>
        {
          viewModel.navigationType === 'Link'
            ? (
              <a href={viewModel.navigationValue} rel="noreferrer" target="_blank" className={styles.cardContainer}>
                <div className={styles.imgContainer}>
                  <img className={styles.img} src={viewModel.thumbnail} alt={viewModel.title} />
                </div>
              </a>
            )
            : (
              <Link to={viewModel.innerLinkTarget} className={styles.cardContainer}>
                <div className={styles.imgContainer}>
                  <img className={styles.img} src={viewModel.thumbnail} alt={viewModel.title} />
                </div>
              </Link>
            )
        }
      </>
    );
  }
}

SwiperCard.propTypes = {
  viewModel: PropTypes.object
};

SwiperCard.defaultProps = {
  viewModel: {}
};

export default SwiperCard;
