import { makeObservable, observable, action, computed } from 'mobx';
import { createSearchParams } from 'react-router-dom';

export default class SwiperCardViewModel {
  @observable id;
  @observable title;
  @observable thumbnail;
  @observable navigationType;
  @observable navigationValue;
  @observable navigationTarget;

  constructor(data) {
    makeObservable(this);

    this.deserialize(data);
  }

  @action
  deserialize = (data) => {
    const {
      id,
      title,
      thumbnail,
      navigationType,
      navigationValue,
      navigationTarget
    } = data;

    this.id = id;
    this.title = title;
    this.thumbnail = thumbnail;
    this.navigationType = navigationType;
    this.navigationValue = navigationValue;
    this.navigationTarget = navigationTarget;
  }

  @computed
  get innerLinkTarget() {
    switch (this.navigationType) {
      case 'Home':
        return '/';
      case 'VideosList':
        return {
          pathname: '/list/video',
          search: `?${createSearchParams({ filter: 'all', sort: 'Date' })}`
        };
      case 'AudiosList':
        return {
          pathname: '/list/audio',
          search: `?${createSearchParams({ filter: 'all', sort: 'Date' })}`
        };
      case 'AudiobooksList':
        return {
          pathname: '/list/audiobook',
          search: `?${createSearchParams({ filter: 'all', sort: 'Date' })}`
        };
      case 'Course':
        return `/course/${this.navigationValue}`;
      case 'CustomList':
        if (this.navigationTarget) {
          return `/bookList/${this.navigationValue}?list=${this.navigationTarget}`;
        }
        return `/bookList/${this.navigationValue}`;
      case 'Me':
        return '/profile/information';
      case 'Subscription':
        return '/subscription';
      default:
        return '/';
    }
  }
}
